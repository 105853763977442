import { t } from '@lingui/macro'
import { useState } from 'react'
import { Form, Input } from '@arco-design/web-react'
import { link } from '@/helper/link'
import { useUserStore } from '@/store/user'
import { postUserLoginEmail, postUserLoginMobile } from '@/apis/login'
import { usePageContext } from '@/hooks/use-page-context'
import { setToken } from '@/helper/auth'
import Footer from '@/features/layout/footer'
import { getThemeTypeImageUrl } from '@/helper/common'
import { emailValidate, TabOption, passwordValidate } from '../user-operate'
import SelectPhone from '../select-phone'
import { useGetMemberArea } from '../use-get-member-area'
import styles from './index.module.css'

const FormItem = Form.Item

/** 回车键 */
const keyCode = 13

function CreateAccount() {
  const [form] = Form.useForm()
  const [valNum, setValNum] = useState<string>(TabOption.Email)
  const { setUserInfo, setLogin, updateUserInfoData } = useUserStore()
  const [isloding, setIsloding] = useState<boolean>(false)
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const pageContext = usePageContext()
  const { redirect } = pageContext.urlParsed.search
  useGetMemberArea(form, 'phone', valNum)

  const setUpPassword = e => {
    setPasswordVisible(e)
  }

  const email = Form.useWatch('email', form)
  const password = Form.useWatch('password', form)
  const phone = Form.useWatch('phone', form)

  const setRegistorChange = async () => {
    const res = await form.validate()
    if (isloding) return
    setIsloding(true)
    if (valNum === TabOption.Email) {
      const { isOk, data } = await postUserLoginEmail({ email: res?.email, loginPassword: res?.password })
      setIsloding(false)
      if (isOk) {
        updateUserInfoData()
        setLogin(true)
        setToken(data)
        redirect ? link(redirect) : link('/')
      }
    } else {
      const { isOk, data } = await postUserLoginMobile({
        mobileCountryCode: res?.phone?.areacode,
        mobileNumber: res?.phone.phone,
        loginPassword: res?.password,
      })
      setIsloding(false)
      if (isOk) {
        updateUserInfoData()
        setToken(data)
        setLogin(true)
        redirect ? link(redirect) : link('/')
      }
    }
  }

  /**
   * tab 切换事件
   */
  const onTab = e => {
    setValNum(e)
    form.clearFields()
  }

  /*
   * 回车登陆
   */
  const onKeyUp = e => {
    if (e.keyCode === keyCode) {
      setRegistorChange()
    }
  }

  return (
    <div
      className={styles.scoped}
      style={{
        backgroundImage: `url(${`${getThemeTypeImageUrl('vpn/swiss_trading')}`})`,
      }}
    >
      <div className="registor-body">
        <div className="registor-bgimg">
          <div className="registor-form">
            <div className="registor-box">
              <div className="registor-title">{t`features_user_operate_login_index_fudwh5lkfn`}</div>
              <div className="registor-box-dl">
                <div
                  className={`registor-em registor-selct-box ${valNum === TabOption.Email && 'registor-selected'}`}
                  onClick={() => onTab(TabOption.Email)}
                >
                  {t`features_user_operate_login_index_ytn4iz5qh6`}
                </div>
                <div
                  className={`registor-selct-box ${valNum === TabOption.Phone && 'registor-selected'}`}
                  onClick={() => onTab(TabOption.Phone)}
                >
                  {t`features_user_operate_login_index_knelwvbshr`}
                </div>
              </div>
              <div className="registor-form-submit">
                <Form layout="vertical" autoComplete="off" requiredSymbol={false} form={form}>
                  {valNum === TabOption.Email ? (
                    <FormItem field="email" rules={[emailValidate()]} label={''} className="registor-email-box">
                      <Input
                        className="registor-email registor-password"
                        placeholder={t`features_user_operate_create_account_index_stitw4ra1u`}
                      />
                    </FormItem>
                  ) : (
                    <FormItem
                      field="phone"
                      rules={[
                        {
                          validator(val, cb) {
                            if (!val?.phone) return cb(t`user.field.reuse_11`)
                            if (val?.phone && !val?.areacode) {
                              return cb(t`features_user_operate_login_index_rkmpqk5u3a`)
                            }
                            return cb()
                          },
                        },
                      ]}
                      label={''}
                    >
                      <SelectPhone />
                    </FormItem>
                  )}
                  <div className="relative">
                    <FormItem label={''} field="password" rules={[passwordValidate()]}>
                      <Input.Password
                        onKeyDown={onKeyUp}
                        className="registor-email registor-password"
                        onVisibilityChange={setUpPassword}
                        placeholder={t`features_user_operate_create_account_index_o0g0eo8azm`}
                      />
                    </FormItem>
                  </div>
                </Form>
              </div>
              {/* isloding */}
              <div
                className={`${(email || phone) && password ? 'registor-but-selected' : ''} registor-agreement-button`}
                onClick={setRegistorChange}
              >
                {isloding
                  ? `${t`features_user_operate_login_index_oxihr2wzdl`}...`
                  : t`features_user_operate_login_index_fudwh5lkfn`}
              </div>
              <div className="registor-small">
                <span className="registor-homepage" onClick={() => link('/reset-password')}>
                  {t`features_user_operate_login_index_8issfd9bjz`}
                </span>
                {/* /register/register-homepage */}
                <span className="registor-homepage" onClick={() => link('/register/create-account')}>
                  {t`features_layout_components_personalization_jcjm2uey5y`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-foot">
        <Footer />
      </div>
    </div>
  )
}

export default CreateAccount
